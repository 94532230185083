import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AuthContext from "../contexts/auth-context";
import React, { useEffect, useState, useContext } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Checkbox, FormGroup, FormControlLabel, TextField } from '@mui/material';
import dayjs from 'dayjs';

const formatDoubleToTimeString = (doubleTime) => {

  if (doubleTime > 24 || doubleTime < 0) return "Invalid"

  var hours = Math.floor(doubleTime);
  const minutes = Math.round((doubleTime - hours) * 100);

  if (minutes >= 60 || minutes < 0) return "Invalid"

  var formattedHours = hours.toString().padStart(2);
  const formattedMinutes = minutes.toString().padStart(2, 0);

  const meridiem = hours >= 12 ? "PM" : "AM";

  if (meridiem === "PM") {
    hours -= 12
    formattedHours = hours.toString().padStart(2);
  }

  return `${formattedHours}:${formattedMinutes} ${meridiem}`;
};

function doubleToDateTime(doubleHour) {
  const hours = Math.floor(doubleHour);
  const minutes = (doubleHour - hours) * 100;
  return dayjs().hour(hours).minute(minutes).second(0);
}

const OperationalHours = ({ openingHours, closingHours, currentId }) => {

  const initialSchedule = [
    { day: 'Sunday', open: openingHours[0].toString(), close: closingHours[0].toString(), isClosed: openingHours[0] === 0 && closingHours[0] === 0, isOpen24Hours: openingHours[0] !== 0 && closingHours[0] !== 0 && openingHours[0] === closingHours[0], isNormalHours: openingHours[0] !== closingHours[0] },
    { day: 'Monday', open: openingHours[1].toString(), close: closingHours[1].toString(), isClosed: openingHours[1] === 0 && closingHours[1] === 0, isOpen24Hours: openingHours[1] !== 0 && closingHours[1] !== 0 && openingHours[1] === closingHours[1], isNormalHours: openingHours[1] !== closingHours[1] },
    { day: 'Tuesday', open: openingHours[2].toString(), close: closingHours[2].toString(), isClosed: openingHours[2] === 0 && closingHours[2] === 0, isOpen24Hours: openingHours[2] !== 0 && closingHours[2] !== 0 && openingHours[2] === closingHours[2], isNormalHours: openingHours[2] !== closingHours[2] },
    { day: 'Wednesday', open: openingHours[3].toString(), close: closingHours[3].toString(), isClosed: openingHours[3] === 0 && closingHours[3] === 0, isOpen24Hours: openingHours[3] !== 0 && closingHours[3] !== 0 && openingHours[3] === closingHours[3], isNormalHours: openingHours[3] !== closingHours[3] },
    { day: 'Thursday', open: openingHours[4].toString(), close: closingHours[4].toString(), isClosed: openingHours[4] === 0 && closingHours[4] === 0, isOpen24Hours: openingHours[4] !== 0 && closingHours[4] !== 0 && openingHours[4] === closingHours[4], isNormalHours: openingHours[4] !== closingHours[4] },
    { day: 'Friday', open: openingHours[5].toString(), close: closingHours[5].toString(), isClosed: openingHours[5] === 0 && closingHours[5] === 0, isOpen24Hours: openingHours[5] !== 0 && closingHours[5] !== 0 && openingHours[5] === closingHours[5], isNormalHours: openingHours[5] !== closingHours[5] },
    { day: 'Saturday', open: openingHours[6].toString(), close: closingHours[6].toString(), isClosed: openingHours[6] === 0 && closingHours[6] === 0, isOpen24Hours: openingHours[6] !== 0 && closingHours[6] !== 0 && openingHours[6] === closingHours[6], isNormalHours: openingHours[6] !== closingHours[6] },
  ];

  const handleTimeChange = (dayIndex, type, newValue) => {

    setShowSubmitHours(true)
    
    const newTime = newValue.hour() + newValue.minute() / 100;
    setSchedule((currentSchedule) =>
      currentSchedule.map((item, index) =>
        index === dayIndex ? { ...item, [type]: newTime.toString() } : item
      )
    );
  };

  const authCtx = useContext(AuthContext);
  const [schedule, setSchedule] = useState(initialSchedule);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSubmitHours, setShowSubmitHours] = useState('');

  useEffect(() => {
    if (openingHours && closingHours && openingHours.length === 7 && closingHours.length === 7) {
      const updatedSchedule = initialSchedule.map((item, index) => ({
        ...item,
        open: openingHours[index].toString() ?? item.open.toString(),
        close: closingHours[index].toString() ?? item.close.toString(),
      }));
      setSchedule(updatedSchedule);
    }
  }, [openingHours, closingHours]);

  async function submitHours() {

    setShowSubmitHours(false)

    setErrorMessage('')

    for (var i = 0; i < schedule.length; i++) {
      if (!schedule[i].isClosed && !schedule[i].isOpen24Hours) {
        if (parseFloat(schedule[i].open) >= parseFloat(schedule[i].close)) {
          setErrorMessage(schedule[i].day + " opening hours cannot be later than closing hours.")
          return
        }
      }
    
    }

    const closingHours = schedule.map(day => day.open.toString());
    const openingHours = schedule.map(day => day.close.toString());

    const formattedOpeningHours = openingHours.map(time => {
      const [hours, decimalMinutes] = time.split('.');
      let minutes = '00';
      if (decimalMinutes) {
        minutes = String(Math.round(parseFloat(`0.${decimalMinutes}`) * 100));
        minutes = minutes.padStart(2, '0');
      }
    
      return `${hours}:${minutes}`;
    });

    const formattedClosingHours = closingHours.map(time => {
      const [hours, decimalMinutes] = time.split('.');
      let minutes = '00';
      if (decimalMinutes) {
        minutes = String(Math.round(parseFloat(`0.${decimalMinutes}`) * 100));
        minutes = minutes.padStart(2, '0');
      }
    
      return `${hours}:${minutes}`;
    });

    const response = await fetch("https://api.queuesapp.com/set-normal-hours-dashboard", {
      headers: {
        "Content-Type": "application/json",
        dashboardToken: authCtx.token,
      },
      method: "POST",
      body: JSON.stringify({
        collection: currentId,
        email: authCtx.email,
        newHours: { formattedClosingHours, formattedOpeningHours },
      }),
    });
    setEditMode(false)
  };

  const handleCheckboxChange = (dayIndex, checkboxType) => {

    setShowSubmitHours(true)

    setSchedule(currentSchedule =>
      currentSchedule.map((item, index) => {
        if (index === dayIndex) {

          if (item[checkboxType]) {
            return item;
          }


          if (checkboxType === 'isClosed') {
            return {
              ...item,
              isClosed: !item.isClosed,
              open: '0.00',
              close: '0.00',
              isOpen24Hours: false,
              isNormalHours: false,
            };
          } else if (checkboxType === 'isOpen24Hours') {
            return {
              ...item,
              isOpen24Hours: !item.isOpen24Hours,
              open: '1.00',
              close: '1.00',
              isClosed: false,
              isNormalHours: false,
            };
          } else if (checkboxType === 'isNormalHours') {
            return {
              ...item,
              isNormalHours: checkboxType === 'isNormalHours' ? !item.isNormalHours : item.isNormalHours,
              isClosed: false,
              isOpen24Hours: false,
            };
          }
        }
        return item;
      })
    );
  };


  return (
    <div className='elevated-card'>
      <div className='hours-title queuesGreen'>
        Operational Hours
        {
          editMode ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '8px' }}>
            <button
              style={{ marginRight: '8px' }}
              className="btn border-1"
              onClick={() => {
                setSchedule(initialSchedule)
                setEditMode(false)
                setShowSubmitHours(false)
              }}
            >
              Cancel
            </button>

            {
              showSubmitHours ?  <button
              className="btn border-1"
              onClick={() => submitHours()}
            >
              Submit
            </button> : null
            }

          </div>
            : < IconButton aria-label="edit" onClick={() => setEditMode(true)}>
              <EditIcon />
            </IconButton>
        }
      </div>

      {errorMessage && <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{errorMessage}</div>}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {schedule.map((item, index, array) => (
          <div key={index} style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            paddingTop: '8px',
            paddingBottom: '8px',
            borderTop: '1px solid #d3d3d3'
          }}>
            <div style={{ flex: 1 }}>{item.day}</div>

            {editMode ? (
              <div style={{ flex: 3 }}>

                {
                  item.isNormalHours ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px' }}>
                      <TimePicker
                        value={doubleToDateTime(parseFloat(item.open))}
                        onChange={(newValue) => handleTimeChange(index, 'open', newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                      <div style={{ width: '8px' }} />
                      <TimePicker
                        value={doubleToDateTime(parseFloat(item.close))}
                        onChange={(newValue) => handleTimeChange(index, 'close', newValue)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                      />
                    </div>
                  ) : null
                }

                <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox sx={{ '&.Mui-checked': { color: '#53b8aa' }, }} checked={item.isClosed} onChange={() => handleCheckboxChange(index, 'isClosed')} />}
                      label="Closed"
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{ '&.Mui-checked': { color: '#53b8aa' }, }} checked={item.isOpen24Hours} onChange={() => handleCheckboxChange(index, 'isOpen24Hours')} />}
                      label="Open 24 Hours"
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{ '&.Mui-checked': { color: '#53b8aa' }, }} checked={item.isNormalHours} onChange={() => handleCheckboxChange(index, 'isNormalHours')} />}
                      label="Normal Hours"
                    />
                  </FormGroup>
                </div>
              </div>
            ) : (

              <div style={{ width: '175px', paddingLeft: '32px'}}>
                {
                  (parseFloat(item.open) === 0 && parseFloat(item.close) === 0) ? 'Closed' :
                  parseFloat(item.open) === parseFloat(item.close) ? 'Open 24 Hours' :
                      `${formatDoubleToTimeString(parseFloat(item.open))} - ${formatDoubleToTimeString(parseFloat(item.close))}`
                }
              </div>

            )}

          </div>
        ))}
      </LocalizationProvider>

      {
          editMode ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '8px', background: 'white',  }}>
            <button
              style={{ marginRight: '8px' }}
              className="btn border-1"
              onClick={() => {
                setSchedule(initialSchedule)
                setEditMode(false)
                setShowSubmitHours(false)
              }}
            >
              Cancel
            </button>

            {
              showSubmitHours ?  <button
              className="btn border-1"
              onClick={() => submitHours()}
            >
              Submit
            </button> : null
            }

          </div>
            : null
        }
    </div>
  );
};

export default OperationalHours;
