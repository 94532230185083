import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/auth-context";
import locationDefinitions from "../floors/locations.json";
import Button from "../components/atoms/Button";
import WaffleHouseLocationCard from "../components/organisms/WaffleHouseLocationCard";
import moment from "moment";
import SmallBusinessLocationCard from "../components/organisms/SmallBusinessLocationCard";


export default function Locations() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [percentOccupancy, setPercentOccupancy] = useState([]);
  const [currentWaitTimes, setCurrentWaitTimes] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);

    let newPercentOccupancies = [];
    let newWaitTimes = []

    if (authCtx.authLocations.length != 0 && authCtx.authLocations[0].collection == "waffleHouse") {
      for (let i = 1; i <= 3; i++) {
        const response = await fetch(
          "https://api.queuesapp.com/get-waffle-house-current-data",
          {
            headers: {
              "Content-Type": "application/json",
              token: authCtx.token,
            },
            method: "POST",
            body: JSON.stringify({
              locationId: i,
            }),
          }
        );

        const data = await response.json();

        var occupancyTotal = 0;
        var totalSeats = 0;

        for (let j = 0; j < data.length; j++) {
          let current = Object.keys(data[j]);

          if (current[0].startsWith("S") || current[0].startsWith("B")) {
            if (data[j][current] !== 0) {
              occupancyTotal++;
            }
            totalSeats++;
          } else if (current[0].startsWith("D")) {
            occupancyTotal += data[j][current];
            totalSeats +=
              locationDefinitions["locations"][i - 1]["seatData"]["chairs"][0][
              "quantity"
              ];
          }
        }

        var percentOccupancy = Math.round((occupancyTotal / totalSeats) * 100);
        if (percentOccupancy < 0) {
          percentOccupancy = 0;
        } else if (percentOccupancy >= 100) {
          percentOccupancy = 100;
        }

        newPercentOccupancies.push(percentOccupancy);
      }
    } else {
      for (let i = 0; i < authCtx.authLocations.length; i++) {

        const response = await fetch("https://api.queuesapp.com/get-current-data", {
          headers: {
            "Content-Type": "application/json",
            token: authCtx.token,
          },
          method: "POST",
          body: JSON.stringify({
            collection: authCtx.authLocations[i].collection
          }),
        });

        const data = await response.json();

        newWaitTimes.push(data["wait time"]);
      }
    }

    setCurrentWaitTimes(newWaitTimes)
    setPercentOccupancy(newPercentOccupancies);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    if (
      authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) < moment(new Date())
    ) {
      authCtx.logout();
      navigate("/auth");
    }
  }, []);

  return (
    <>
      {authCtx.loggedIn &&
        moment.unix(authCtx.tokenExpirationTime) > moment(new Date()) ? (
        <>
          <div className="banner top">
            <h2>Locations</h2>
          </div>
          <div className="p-4">
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm m-2"
                role="status"
                aria-hidden="true"></span>
            ) : (
              <Button
                className="border-0"
                onClick={fetchData}
                icon="arrow-clockwise"
                text="Refresh"
              />
            )}
            <div className="row mt-4">
              {authCtx.authLocations.map((x, index) => 
                
                  x.collection == 'waffleHouse' ? (<WaffleHouseLocationCard
                    key={x.id}
                    item={x}
                    percent={percentOccupancy[index]}
                  />) : (<SmallBusinessLocationCard
                    key={x.id}
                    item={x}
                    waitTime={currentWaitTimes[index]}
                  />)
                
                
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="p-4">
          {authCtx.loggedIn &&
            moment.unix(authCtx.tokenExpirationTime) < moment(new Date()) ? (
            <>
              <Link to={"/auth"}>
                Session Token expired or invalid. Please log out, then log back
                in for a new session token
              </Link>
            </>
          ) : (
            <>
              <Link to={"/auth"}>
                Not Authorized. Please Log-In with given credentials or contact
                support.
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}
