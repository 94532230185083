import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./App.css";
import Nav from "./components/molecules/Nav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SmallBusinessLocation from "./pages/SmallBusinessLocation";
import Locations from "./pages/Locations";
import Location from "./pages/Location";
import { AuthContextProvider } from "./contexts/auth-context";
import Auth from "./pages/Auth";
import ForgotPassword from "./pages/ForgotPassword";
import Parking from "./pages/Parking";
import Reports from "./pages/Reports";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Nav />}>
            <Route path="/auth" element={<Auth />}></Route>
            <Route
              path="/auth/forgotPassword"
              element={<ForgotPassword />}></Route>
            <Route path="/locations" element={<Locations />}></Route>
            <Route path="/location/:id" element={<Location />}></Route>
            <Route path="/smallBusinessLocation/:id" element={<SmallBusinessLocation />}></Route>
            {/* <Route path="/export" element={<Export />}></Route> */}
            <Route path="/reports" element={<Reports />}></Route>
            <Route path="/parking" element={<Parking />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
