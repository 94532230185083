import { useContext, useState, useEffect } from "react";
import AuthContext from "../contexts/auth-context";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo_1x.png";
import Button from "../components/atoms/Button";
import { getQueuesAPI } from "../util";
import Input from "../components/atoms/Input";

export default function Auth() {
  const authCtx = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // If you use the back button to logout, it makes sure to log you out.
  useEffect(() => {
    authCtx.logout();
  }, [navigate]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const login = (e) => {
    e.preventDefault();
    getQueuesAPI(
      "loginFrontEnd",
      {
        email: email,
        pwd: password,
      },
      (data) => {
        if (data.success) {
          setError("");
          authCtx.login(
            email,
            data.name,
            data.sessionToken,
            data.expiration_time,
            data.authLocations
          );
          navigate("/locations");
        } else {
          setError("Invalid username or password");
          setTimeout(() => setError(""), 1500);
        }
      }
    );
  };

  return (
    <div className="centered-container">
      <img alt="" width="225" src={logo} />
      <form onSubmit={login} className="col-4 p-3">
        <Input
          required={true}
          className="mb-3"
          placeholder="E-mail"
          value={email}
          onChange={onChangeEmail}
        />
        <Input
          required={true}
          className="mb-3"
          placeholder="Password"
          type="password"
          value={password}
          onChange={onChangePassword}
        />
        <Button
          className="w-100"
          type="submit"
          icon={error !== "" ? "exclamation-circle-fill" : ""}
          text={error === "" ? "Log In" : error}
        />
      </form>
      <Link to="forgotPassword">
        <Button text="Forgot Password?" className="border-0" />
      </Link>
    </div>
  );
}
